.webviewer {
 width: 100% !important;
 
  /* height: 80vh; */
  /* min-height: 550px; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  background-color: white !important;
  top: 7.5%;
  /* bottom: 2% !important; */
  left: 0% ;
  height: 83%;
  /* margin-left: -13px; */
  z-index: 1;
  // *::-webkit-scrollbar {
    // width: 10px !important;
  // }
    // scrollbar-width: thin ;
    // scrollbar-color: rgba(155, 155, 155, 0.7) transparent !important;
}
// }
/* .webviewer span {
  text-align: center;
  position: absolute;
  width: 100%;
} */

.closetag{
  top: 7.5% !important;
  right: 3%;
  z-index: 2;
  cursor: pointer;
  position: fixed;
}
.download{
  top: 25% !important;
  a{
    margin-top: 4px;
    text-decoration: none;
    color: white;
  }
  right: 3%;
  z-index: 2;
  cursor: pointer;
  position: fixed;
  border-radius: 50%;
  font-size: 18px;
  color: white;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #1784ba;
  // margin-bottom: 5px;
  display: flex;
  width: 30px;
  height: 30px;
}
.border{
  
  /* margin: -0.7rem; */
  /* height: 570px !important; */
  height: 80%;
  width: 100% !important;
  /* position: fixed !important; */
  position: relative;
}
.zoom{
  top: 14.5% !important;
  right: 3%;
  z-index: 2;
  cursor: pointer;
  position: fixed;
  span{
    border-radius: 50%;
    font-size: 20px;
    color: white;
    text-align: center;
    align-items: center;
    justify-content: center;
    background-color: #1784ba;
    margin-bottom: 5px;
    display: flex;
    width: 30px;
    height: 30px;
  }
  .disable{
    pointer-events: none;
  }

}



.model{
  width: 100%;
  height: 80%;
  /* position: absolute !important; */
 
  /* display: flex; */
  justify-content: center;
  align-items: center;
}

.rpv-core__inner-page{
  width: 96% !important;
}