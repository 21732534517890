
.react-chatbot-kit-chat-header {
  background-color: #2983c5 !important;
  /* width: 350px !important; */
  color: #fff !important;
}

.react-chatbot-kit-chat-bot-message {
  font-size: 12px !important;
  background-color: #dbeaf5 !important;
  color: black !important;
  margin-left: 2px !important;
}

.react-chatbot-kit-chat-bot-message-arrow {
  border-right: 8px solid #dbeaf5 !important;
  top: 0px !important;
}
.react-chatbot-kit-chat-bot-avatar-container {
  background-color: #e8e8e8 !important;
}

.react-chatbot-kit-user-chat-message {
  max-width: 70% !important;
  font-size: 10px !important;
  background-color: #2983c5 !important;
  color: #fff !important;
}
.react-chatbot-kit-user-chat-message-arrow {
  border-left: 8px solid #2983c5 !important;
  top: 0px !important;
}
.react-chatbot-kit-chat-bot-avatar-letter{
  color: white !important;
  font-size: 18px;
}

.react-chatbot-kit-user-avatar-container {
  background-color: #ff6262 !important;
  color: white !important;
}

.react-chatbot-kit-chat-message-container {
  position: relative !important;
  border-left: 0.2px solid gainsboro !important;
  border-right: 0.2px solid gainsboro !important;
  background-image: url("../public/assets/messageContainerBG.png") !important;
  background-size: contain;
  /* height: 65vh !important; */
  padding: 0px 7px 10px !important;
  /* width: 100% !important; */
  height: 82% !important;
  /* padding: 0.7rem !important; */
  overflow-x: hidden !important;
  -webkit-touch-callout: none;

  /* user select - copy paste option */
/* -webkit-user-select: none;
-khtml-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none; */
}
.react-chatbot-kit-chat-bot-message {
  width: 70% !important;
}

.react-chatbot-kit-chat-inner-container {
  position: relative !important;
  width: 100% !important;
  height: 100% !important;
  border-radius: 25px !important;
  /* height: 75vh !important; */
}

.react-chatbot-kit-chat-btn-send {
  background-color: #fff !important;
  padding: 0.1rem;
  margin-top: 5px !important;
}

.react-chatbot-kit-chat-btn-send-icon {
  background-color: #2983c5 !important;
  /* padding: 0.7rem; */
  /* width: 0.8rem !important; */
  border-radius: 50%;
}
.react-chatbot-kit-chat-input-container {
  /* position: relative !important; */
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: #fff !important;
  width: inherit !important;
  /* padding: 5px 0px; */
  height: 8.5%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 0.4rem 0.73rem !important; */
}

.react-chatbot-kit-chat-input-form {
  /* width: 350px !important; */
  margin-bottom: 0px !important;
  align-items: center !important;
}
.react-chatbot-kit-chat-input {
  margin: 0px;
  color: #696969 !important;
  padding: 4px;
  border-bottom: 1px solid gainsboro !important;
  border: 1px solid gainsboro !important;
  padding: 10px !important;
  /* width: 15vw !important; */
  margin-right: 20px;
}
.react-chatbot-kit-main-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  margin-right: 15px;
  cursor: pointer;
}

/* ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  /* color: #bababa !important; */
  /* opacity: 1;  */
/* } */ 
/* textarea:focus,
input:focus {
  outline: none;
} */
.Error-Message{
  color: red;
  
}

.react-chatbot-kit-chat-container{
  /* max-width: 450px !important; */
  height: 100vh; 
} 




@media only screen and (max-width: 1100px) {
  .react-chatbot-kit-chat-container{
    width: 450px !important;
    height: 97vh; 
  } 

}
@media only screen and (min-width: 1100px) {
  .react-chatbot-kit-chat-container{
    min-width: 450px !important;
    height: 97vh; 
  } 
}
@media only screen and (max-width: 800px) {
  .react-chatbot-kit-chat-container{
    width: 400px !important;
    height: 97vh; 
  } 
}
@media only screen and (max-width: 450px) {
  .react-chatbot-kit-chat-container{
    width: 95vw !important;
    min-height: 87vh;
    height: calc(var(--viewport-height-for-Chatbot) - 55px);
    /* height: 100%; Fallback for browsers that do not support Custom Properties */
  /* height: calc(var(--vh, 1vh) * 97) !important; */
  } 
}

:root {
  --viewport-height-for-Chatbot: 100vh;
}

/* html{
  font-size: 62.5%;
} */



