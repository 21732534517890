.user-message{
    margin-bottom: 15px;
    font-size: 12.7px !important;
    font-weight: 500 !important;
}
.timestamp-user{
    font-size: 10px;
    color: #252D59;
    font-weight: 600;
    width: max-content;
    position: absolute;
    bottom: -20px;
    right: 0%;
    // color: black;
    // background-color: white;
}