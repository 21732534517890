@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Oleo+Script+Swash+Caps&family=Poppins:wght@300;400;500;600;700;800&family=Smooch&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/regular';


.App{

  margin-right: 5px;
      font-size: 14px;
  font-size: 16px;
  line-height: normal;
    // overflow: hidden;
    // background-image: url("../../../public/assets/linkBack.png");
    // height: 100vh;
    // background-size: contain;
    margin: 0;
    font-family: "Roboto"!important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    *::-webkit-scrollbar-thumb {
      background-color: rgba(155, 155, 155, 0.7);
      border-radius: 20px;
      border: transparent;
    } 
    *::-webkit-scrollbar {
      width: 10px !important;
    }
    // div,span{
    //   font-size: 16px;
    // }
    // strong{
    //   font-weight: 700;
    // }
  *{
    box-sizing: content-box;
    p{
      margin: 0px;
      padding: 3px;
    }
    
    // font-size: 101%;
  }
    
      scrollbar-width: thin;
      scrollbar-color: rgba(155, 155, 155, 0.7) transparent;
    

    &-ChatBotContainer{
       //  mobile 
       display: flex;
       justify-content: flex-end;
       @media only screen and (max-width: 450px) {
        justify-content: center;
      }
   
  
//  tab 
    
      @media only screen and (max-width: 800px) and (min-width: 450px) {
        justify-content: center;
      }
  
  
  // desktopQuater
    
      @media only screen and (max-width: 1100px) and (min-width: 800px) {
        // ${props}
      }
    
  
//  desktop half
      @media only screen and (min-width: 1100px) {
        // ${props}
      }
  
  
    }

 
}
.initial-MessageChat{
  font-family: 'Oleo Script Swash Caps', cursive !important;
  font-size: 15px;
            // color: #fff;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  padding: 3px 0px;
  @media (max-width: "450px") {
    font-size: 13px;
  }

  @media (max-width: "380px") {
    font-size: 12px;
  }

}
.ida-logo{
  margin-top: -3px;
  width: 40px;
  margin-left: 5px;
}
// #root{
//   font-size: 16px !important;
// }

div{
  box-sizing: border-box;
}

