// @font-face {
  //   font-family: "Triumph";
  //   src: local("Triumph"),
  //     url("./../../../public//assets//SVN-UT\ Triumph\ Regular.otf") format("truetype");
  //   font-weight: normal;
  // }
.header{
  position: relative;
  // z-index: 98;
  &-text{
    display: flex;
    cursor: pointer;
    // align-items: flex-start;
  }

  &-menu{
    position: absolute;
    right: 13% ;
    cursor: pointer;
    // margin-top: 7px;
    img{
      margin-top: 2px !important;
      width: 33px !important;
    }
    @media (max-width: "450px") {
      right: 15%;
    }
  }
  
  &-menu_close{
    position: absolute;
    right: 49% ;
    cursor: pointer;
    // margin-top: 7px;
    img{
      margin-top: 2px !important;
      width: 33px !important;
    }
    @media (max-width: "450px") {
      right: 53%;
    }
  }
  &-location{
    position: absolute;
    right: 40% ;
    cursor: pointer;
    // margin-top: 7px;
    img{
      margin-top: 2px !important;
      width: 33px !important;
    }
    @media (max-width: "450px") {
      right: 43%;
    }
  }
  &-telephone{
    position: absolute;
    right: 31% ;
    cursor: pointer;
    // margin-top: 7px;
    img{
      margin-top: 2px !important;
      width: 33px !important;
    }
    @media (max-width: "450px") {
      right: 33%;
    }
  }
  &-email{
    position: absolute;
    right: 22% ;
    cursor: pointer;
    // margin-top: 7px;
    img{
      margin-top: 2px !important;
      width: 33px !important;
    }
    @media (max-width: "450px") {
      right: 23%;
    }
  }
  &-rate{
    position: absolute;
    right: 13% ;
    cursor: pointer;
    // margin-top: 7px;
    img{
      margin-top: 2px !important;
      width: 33px !important;
    }
    @media (max-width: "450px") {
      right: 13%;
    }
  }

  
  font-family: 'Oleo Script Swash Caps', cursive;
    // font-family: Triumph;
    letter-spacing: 1.5px;
    font-size: 20px;
    // background-color: #BCD6E9;
    background-color: #2983C5;
    color: white;
    height: 8%;
    padding-left: 13px;
    // padding: 10px;
    // padding-top: 15px;
    // padding-bottom: 15px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img{
      margin-top: -5px;
      width: 60px;
      margin-left: 2px;
    }
    &-light{
      font-weight: 400;
      // color: #252D59;
      color: white;
    font-size: 20px;
    }
    
    &-notification{
      display: flex;
      justify-content: center;
      top: 100%;
      width: 110%;
      position: absolute;
       padding: 5px 10px;
       font-size: 0.9rem;
       font-family: "Roboto";
       margin-left: -10%;
       z-index: 99;
      &-text{
        border-radius: 5px;
        overflow: hidden;
        letter-spacing: normal !important;
        animation: reveal 1 0.7s;
        width: 75%;
        color: white;
        padding: 10px 5px;
        background-color: #252d59;
      
        text-align: center;
        
       
      }
      // padding-top: 40px;
    }
}

@keyframes reveal{
	0%{
   opacity: 0;
		transform: translateY(-100%);
	}
	100%{
    // display: inline-block;
		transform: translateY(0%);
	}
}