.feedbackRating{
    margin: 5px 0px;
    &-container{
        display: flex;
        flex-direction: column;
        margin-top: 5px;
        &-select{
            border: 0.5px gray solid ;
            border-radius: 3px;
            margin: 5px 0px 8px 0px;
            &:focus{
                outline: 1px solid #2983C5;
            }   
        }
        &-counter{
            display: flex;
            width: 100%;
            justify-content: flex-end;
            margin-top: -5px;
            margin-bottom: 4px;
            font-size: 10.5px;
          
        }
        textarea{
            margin-bottom: 10px;
            resize: none;
            border-radius: 5px;
            padding: 5px;
            font-family: "Roboto"!important;
            font-size: 13px;
            &:focus{
                outline: 1px solid #2983C5;
            }   
            
        }
        &-box{
            display: flex;
            justify-content: space-between;
        }
        &-btnContainer{
            // display: flex;
            width: 100%;
            margin-left: 3px;
            // justify-content: center;
            &-btn{
                padding: 5px 20px;
                color: white;
                background-color: #2983C5;
                cursor: pointer;
                border: none;
                outline: none;
            }
            &-disabled{
                padding: 5px 20px;
                background-color: gray;
                color: white;
                border: none;
                outline: none;
            }
        }
    }
}