.banner{
    width: 100%;
    height: 220px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &-container{
        width: 95%;
        height: 85%;
        background-color: #DBEAF5;
        display: flex;
        // flex-direction: column;
        text-align: center;
        padding: 5px;
        font-size: 10px;
        color: #262D5B;
        &-left{
            width: 30%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                width: 70%;
                pointer-events: none;
                @media (max-width: "450px") {
                    width: 80%;
                }
            }
        }
        &-right{
            width: 70%;
            display: flex;
            &-top{
                display: flex;
                flex-direction: column;
                height: 35%;
                width: 100%;
                span{
                    font-size: 13px;
                }
                @media (max-width: "450px") {
                    span{
                        font-size: 13px;
                    }
                }
                @media (max-width: "380px") {
                    span{
                        font-size: 11px;
                    }
                }
                &-withFont{
                    width: 40px;
                    margin-bottom: 5px;
                    // font-family: 'Oleo Script Swash Caps', cursive;
                  margin-left: 5px;
                //   font-weight: 600;
                //   color: #2799d6;
                //     letter-spacing: 2px;
                //     font-size: 16px;
                    
                }
                &-withIcon{
                    font-size: 14px !important;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 2px;
                    font-weight: 500;
                   
                    // img{
                    //     margin-left: -10px;
                    // }
                }
            }
            &-left{
                width: 30%;
                img{
                    width: 75%;
                    margin-top: 5px;
                }
            }
            &-right{
                font-size: 11.5px;
               
               
                &-top{
                    span{
                        display: flex;
                        align-items: center;
                        img{
                            width: 8px !important;
                        }
                    }
                    &-active{
                        display: flex;
                        align-items: center;
                        // img{
                        //     width: 8px !important;
                        // }
                        cursor: pointer;
                        &:hover{
                            font-weight: 500;
                            text-decoration: underline;
                        }
                    }
                    padding: 0px 10px;
                    text-align: left;
                    margin-top: 5px;
                    display: flex;
                    flex-direction: initial;
                    flex-wrap: wrap;
                    width: 100%;
                    height: 50%;
                    span{
                        display: flex;
                        padding: 5px 0px;
                        width: 50%;
                        img{
                            margin-right: 5px;
                        }
                        
                        @media (max-width: "450px") {
                            font-size: 13px;
                            padding: 4px 0px;
                            img{
                                margin-right: 5px;
                            }
                        }
                        @media (max-width: "380px") {
                            font-size: 11.5px;
                            padding: 3px 0px;
                            img{
                                margin-right: 5px;
                            }
                        }
                    }
                    
                }
                &-mid{
                    text-align: right;
                    margin-top: -9px;
                    margin-bottom: 2px !important;
                    span{
                        font-size: 15px;
                        color: #FF5454;
                        font-weight: 600 !important;
                       cursor: pointer;
                       
                    }
                }
                &-bottom{
                    &-des{
                        margin-left: 2px;
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        font-weight: 500;
                        border: 1.5px solid black;
                        width: 12px;
                        font-size: 12px;
                        border-radius: 50%;
                        height: 12px;
                    }
                    padding-top: 5px;
                    border-top: 1px solid rgba(38, 45, 91, 0.27);
                    @media (max-width: "380px") {
                        padding-top: 3px;
                        font-size: 10.5px;

                    }
                }
                // width: 70%;
                display: flex;
                flex-direction: column;
            }
        }
    }
    &-timestamp{
        
    font-size: 10px;
    color: #252D59;
    width: max-content;
    position: absolute;
    bottom: -2px;
    font-weight: 600;
    left: 2%;
    }
}

.rpv-core__viewer,.rpv-core__viewer--light{
    &::-webkit-scrollbar {
        width: 5px !important;
      }
        scrollbar-width: thin !important;
        scrollbar-color: rgba(155, 155, 155, 0.7) transparent !important;
      
}