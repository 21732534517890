.timestamp{
    font-size: 10px;
    position: absolute;
    color: #252D59;
    font-weight: 600;
    width: max-content;
    bottom: -20px;
    
    
    left: 0%;
    // background-color: white;
}
.bot-message{
    [class^="fa-"]{
       margin-right: 5px;
        font-size: 15px !important;
        color: #252D59 !important;
     }
    margin-bottom: 10px;
    font-size: 12.7px !important;
    color:#252D59 !important;
    font-weight: 500 !important;
    text-align: justify;
    a{
        word-break: break-all !important;
    }
    // .icon-envelope-alt{
    //     font: 1em/1.4 Cambria, Georgia, sans-serif;
    //     color: #333;
    //     position: relative;
    //     z-index: 1;
    //     overflow: hidden;
    //     list-style: none;
    //     padding: 0;
    //     margin: 0 0 0.25em;
    // }
}
.read-more-or-less{
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-top: 2px solid rgb(0, 204, 255);
    a{
        
        text-decoration: none;
        color: inherit;
        cursor: pointer;
    }
}

.react-chatbot-kit-chat-bot-avatar-container-img{
    width: 36px ;
}

.branchInfo{

        background-color:#dbeaf5 !important;
        color: white !important;
    
  }

.borderbranchInfo{
        border-right: 8px solid #dbeaf5 !important;
  }