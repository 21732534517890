.react-chatbot-kit-chat-bot-avatar{
    &-container{
        background-color: #252D59 !important;
        &-text{
            font-family: 'Oleo Script Swash Caps', cursive !important;
            // font-family: Triumph;
            font-size: 11px;
            color: #fff;
            letter-spacing: 1.5px;
        }
    }
   
}