.MessageHandler{
    // font-size: 0.8rem !important;
    display: flex;
    flex-direction: column;
    a{
        color: #2f3f9d !important;
        text-decoration: none;
        // &:visited{
        //     color: #77016f !important;
        // }
    }
    // &-container{
    //     &-popup{
    //         position: fixed;
    //         padding: 15px 10px;
    //         background-color: #0a90a5;
    //         // display: flex;
    //         bottom: 10%;
    //         // right: 50%;
    //         // transform: translate(-50% -50%);
    //         // align-items: center;
    //         // justify-content: center;
    //         width: 88%;
    //         margin-left: -13%;
    //         max-height: 40vh;
    //         color: white;
    //         z-index: 99999;
    //         // overflow: scroll;
    //         &-close{
    //             position: absolute;
    //             top: -10%;
    //             font-size: 25px !important;
    //             right: -5%;
    //             background-color: rgba(16, 21, 1, 0.724);
    //             width: 40px;
    //             height: 40px;
    //             border-radius: 50%;
    //             display: inline-flex;
    //             align-items: center;
    //             justify-content: center;
    //             cursor: pointer;
    //             transform: rotate(45deg);
               
    //         }
            
    //     }
    // }
    &-botmessage{
        // border-bottom: 3px solid rgb(0, 204, 255);
        width: 100%;
        // padding-bottom: 10px;


        h4{
            margin: 0px !important;
            font-size: 12.7px !important;
            font-family: "Roboto"!important;
        }
        .popup{
            display: none;
        }
        .title-handler{
            // cursor: pointer;
            // font-weight: bold;
            // font-style: italic;
            margin-bottom: 9px;
            display: block;
            margin-left: 3px;
            // color: #0093FF;
            // text-decoration-line: underline;
            // text-decoration-style: dashed;
            // text-underline-offset: 1px;
            // text-decoration-thickness: 1.5px;
            // text-decoration-color:#de9d07;
            img{
                height:15px; 
                width:15px; 
                margin-left: 5px; 
                margin-bottom: -2px;
                cursor: pointer;
            }
            
        }
        &-read{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            color: #0921a9;
            font-weight: 500 !important;
           
            img{
                padding: 5px;
                background-color:  #2f3f9d;
                // margin: 0px 5px;
                margin-top: 5px;
                margin-left: 5px;
                width: 10px;
            }
            &-up{
                
                background-color: #ff6e6e !important;
                transform: rotate(-180deg);
            }
            a{
                height: max-content;
                display: flex;
                align-items: center;
                justify-content: center;
                text-decoration: none;
                color: inherit;
            }
            padding: 5px 0px;
            // border-top: 1px solid rgba(37, 45, 89, 0.27);
        }
    }
    
    &-btn{
        margin: 4px;
        // border-radius: 25px;
       
        width: 75px;
        padding: 8px 0px;
        border: 2px solid #2983C5;
        background-color: #2983C5;
        color: white;
        text-align: center;
        cursor: pointer;
        font-size: 10px;
        &-active{
            background-color: #939393;
            color:  white;
            border: 2px solid #939393;
        }
        
         /* max-width: 130px;
         min-width: 70px; */
    }
    &-url{
         margin-top: 7px;
        padding-top: 5px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-top: 1px solid rgba(37, 45, 89, 0.27);
        width: 100%;
    }
    &-btns{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        margin-top: 5px;
        padding-top: 5px ;
        border-top: 1px solid rgba(37, 45, 89, 0.27);
        // margin-top: 10px;
        &-name{
            padding: 5px 0px;
            text-align: center;
            font-size: 10px;
        }
        &-container{
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
    }
}