.Container{
  z-index: 100001;
  position: fixed;
  transition: all 1s ease;
  // animation: popup 1s ease;
  // overflow: hidden;
  &-minimized{
    display: flex;
    align-items: center;
    cursor: pointer;
    
    &-text{
      color: white;
      box-shadow: rgba(0, 0, 0, 0.24) 4px 4px 8px;
      // font-family: 'Oleo Script Swash Caps', cursive;
    // font-family: Triumph;
    // letter-spacing: 1.5px;
      padding: 15px 5px;
      border-radius: 5px;
      margin-right: -2px;
      position: relative;
      padding-right: 13px ;
      &-close{
        z-index: 9999;
        background-color: red;
        width: 10px;
        height: 10px;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        cursor: pointer;
        position: absolute;
        border: 1px solid red;
        border-radius: 50%;
        right: 1%;
        top: 8%;
        transform: rotate(45deg);
        font-size: 14px;
        span{
          // position: absolute;
        
        //  margin-left: 2px;
         margin-top: -4px;
        }
       
      }
     
      background-color: #252D59;
      &-arrow{
       
          content: '';
          // position: absolute;
          // margin-left: 5px;
          display: block;    
          width: 0px;        
          right: 0;
          top: 50%;
          border: 25px solid transparent;
          border-right: 0;
          border-left: 25px solid #252D59;
          // transform: translate(calc(1% + 5px), -50%);  
        
      }
    }
  }
    // position: absolute;
      bottom: 2% !important;
      height: auto !important;
    //   width: 350px !important;
      /* box-shadow: 0.8px 0.8px 0.8px 0.8px rgba(0, 0, 0,0.5); */
      // margin: 4rem;
      // margin-bottom: 1rem;
      margin-right: 2px;
      @media only screen and (max-width: 800px) {
        bottom: 2% !important;
       margin: 0px;
      }
      &-ChatbotIcon{
        // overflow: hidden;
        // box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.25);
        cursor: pointer;
        // background-color: #fff;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        // flex-direction: column;
        width: 120px;
        height: 130px;
        // border-radius: 50%;
        // color: black;
        position: relative;
        img{
          // top: 1%;
          position: absolute;
          width: 120px;
          margin-left: 1px;
          pointer-events: none;
        }
        span{
          
          color: #F9545D;
          font-weight: 500;
          font-family: 'Oleo Script Swash Caps', cursive;
   
           letter-spacing: 1.5px;
        }
        caret-color: transparent !important;
      }
      &-ChatbotContainer{
        // width: 350px !important;
        overflow: hidden;
        transition: all 1s ease;
        animation: popup  cubic-bezier(0.4, 0, 0.2, 1) 500ms backwards;
          filter: drop-shadow(3px 1px 4px gray);
          &-CloseTags{
            height: 8%;
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                top: 0%;
                right: 0%;
                cursor: pointer;
          }

          &-close{
              display: none;
              cursor: pointer !important;
              caret-color: transparent !important;
          }
      }
}
.IconHandler{
  
  //  color: #252D59;
  color: white;
   width: 45px;
   display: flex;
  //  height: 10%;
   align-items: center;
   img{
    width: 33px;
    height: 33px;
   }
   &-close{
       display: none;
   }
   &-open{
    //  transition: all 0.5s ease;
       color: white;
       display: flex;
       align-items: center;
       cursor: pointer !important;
       caret-color: transparent !important;
   }
}

@keyframes popup {

 
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  
}