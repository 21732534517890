.ButtonContainer{
    margin-left: 12%;
    @media (max-width: "700px") {
        margin-left: 14%;
        
        
    }
    @media (max-width: "380px") {
        margin-left: 16%;
        
    }
    &-timestamp{
        font-size: 10px;
        margin-top: 8px;
        // position: absolute;
        color: #252D59;
        font-weight: 600;
        width: max-content;
        bottom: -20px;
        
        
        left: 0%;

    }
    
    width: 75%;
    display: flex;
    flex-wrap: wrap;
    &-horizontal{
        &-withoutMsg{
            margin-top: -17%;
        }
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        list-style: none;
        width: 100%;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        padding: 0px 0px;
        border-radius: 8px;
        &-head{
            background-color: #E5E5E5 !important;
            border-bottom: none !important;
            color: #252D59;
            font-weight: 600 !important;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            cursor: auto !important;
        }
        &-item{
            &-moreless{
                justify-content: flex-end;
                font-weight: 600;
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
                cursor: auto !important;
                img{
                    width: 10px;
                }
            }

            &-more{
                cursor: pointer;
                background-color: #252D59;
                padding: 6px;
                display: flex;
                margin-left: 5px;
                
                
            }
            &-less{
                cursor: pointer;
                background-color: #ff6e6e !important;
                padding: 6px;
                margin-left: 5px;
                display: flex;
            }
            &-load{
                display: flex;
                align-items: center;
                font-weight: 600;
            }
            &-arrowup{
                transform: rotate(-180deg);
              
            }
            &-arrowdown{
             
            }
            color: #0e4e89 !important;
           
            display: flex;
            justify-content: space-between;
            background-color: white;
            &:not(:last-child){
                border-bottom: 1px solid rgba(0, 0, 0, 0.183);
            }

            &-arrow{
                // font-size: 1rem;
                color: #F26729;
                font-weight: 800;
                // color: red;
            }
            
            font-size: 12.7px;
            cursor: pointer;
            padding: 8px 5px;
            // text-align: left;
            align-items: center;
            // width: max-content;
            &:hover{
                font-weight: 500 ;
            }
            &-highlight{
                // font-weight: 500;
                // color: white !important;
                background-color: whitesmoke;
                &:hover{
                    font-weight: 500 ;
                }
               
            }
        }
    }
}
.main-btn{
    margin: 4px;
    border-radius: 25px;
    padding: 8px 10px;
    color: #0e4e89;
    // color: #252D59;
    font-weight: 500;
    border: 1px solid #3849a8;
    background: transparent;
    text-align: justify;
    cursor: pointer;
    font-size: 12.7px;
     /* max-width: 130px;
     min-width: 70px; */
     @media (max-width: "380px") {
        font-size: 12px;
      }

      &-ipo{
        // background: rgb(171, 171, 171);
        border: 2px solid #F26729;
        color: #F26729;
      }
      &-disable{
       display: none;             
      }
  }

