.isin{

    
    &-form{
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0px;
        &-input{
            width: 98%;
            margin: 10px;
            border-radius: 5px;
            border: 1px solid rgba(128, 128, 128, 0.434);
            &:focus{
                outline: 1px solid #2983C5;
            }   padding: 5px;
            &:disabled{
                cursor: default;
                background-color: rgba(192, 192, 192, 0.146);
                color: rgb(71, 71, 71);
                border-color: rgba(192, 192, 192, 0.146) ;
            }
        }
        &-select{
            // cursor: pointer;
            select{
                cursor: pointer;
                height: 15px !important;
            }
            width: 98%;
            // margin: 0px 10px 10px 10px;
            border-radius: 5px;
            border: 1px solid rgba(128, 128, 128, 0.434);
            &:focus{
                outline: 1px solid #2983C5;
            }   
            // padding: 5px;
            &:disabled{
                cursor: default;
                background-color: rgba(192, 192, 192, 0.146);
                color: rgb(71, 71, 71);
                border-color: rgba(192, 192, 192, 0.146) ;
            }
        }
        &-btn{
            padding: 5px 15px;
            width: max-content;
            color: white;
            background-color: #2983C5;
            cursor: pointer;
            border: none;
            outline: none;
            font-family: 'Roboto';
            letter-spacing: inherit;
            font-size: inherit;
            &:disabled{
                background-color: gray;
                cursor: auto;
            }
        }
        &-suggestion{
            background-color: white;
            box-shadow: rgba(6, 24, 44, 0.183) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
            position: absolute;
            display: flex;
            border-radius: 5px;
            flex-direction: column;
            bottom: 110%;
            width: 100%;
            padding: 5px ;
            &-item{
                cursor: pointer;
                // margin-top: 5px;
                padding: 5px;
                &:not(:last-child){
                    border-bottom: 1px solid black;
                }
                &-error {
                    color: red;
                }
            }
        }
    }
    &-reserch{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        [class^="fa-"]{
            cursor: pointer;
            color: #ff6262 !important;
            
        }
        
    }
}