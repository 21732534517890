.branch-address {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    &-header{
        margin-bottom: 10px;
        color:black;
    }
    &-container{

        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 15px;
        // grid-auto-rows: minmax(15px, auto);

        &-uniq {
            width: 90%;
            height: 16px;
            padding: 5px 6px;
            border: 1px solid #74b7e6;
            background-color: #74b7e6;
            border-radius: 6px;
            color: black;
            text-align: center;
            cursor: pointer;
            font-size: 11.5px;
            &:hover{
                background-color: #f26729;
                border: 1px solid #f26729;
                color: white;
            }
            &-active {
                background-color: #939393;
                color: white;
                border: 2px solid #939393;
            }
            @media (max-width: "450px") {
                font-size: 11px;
                padding: 2px 2px;
              }
        }
    }
    
}

.branchContainer{
    color: black;
    &-content {
        &-address{
          &-mapContainer{
            position: fixed;
            width: 100%;
            margin-left: -7px;
            height: 92%;
            justify-content: center;
            align-items: center;
            z-index: 99997;
            bottom: 0%;
            &-loader{
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                height: 100%;
                width: 100%;
                background-color: white;
            }
            &-icons{
                display: flex;
                flex-direction: column;
                align-items: center;
                position: absolute;
                right: 0%;
                cursor: pointer;
                [class^="fa-"]{
                    margin-right: 5px;
                     font-size: 25px !important;
                     color: black !important;
                     margin-top: 5px;
                     &:hover{
                        color:  orange !important;
                     }
                  }
            }
            &-iframe{
                width: 100%;
                height: 100%;
            }
          }
            &-text{
                margin: 5px 0px;
                &-heading{
                    display: flex;
                    align-items: center;
                    margin-bottom: 5px;
                }
                display: flex;
                flex-direction: column;
            }
    

            &-map{
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 10px;
                border-top: 1px solid black;
                &-btn{
                    cursor: pointer;
                    color: white;
                    text-decoration: none;
                    background-color: #3d90cc;
                    padding: 8px 18px;
                    margin-top: 5px;
                    border-radius: 6px;
                    &:hover{
                        background-color: #f26729;
                    }
                }
    
            }
        }

        &-phone, &-email{
            &-header{
                display: block;
                text-align: center;
                font-weight: bold;
                margin-bottom: 10px;
            }
            &-content{
                display: flex;
                align-items: center;
               &-text {
                   color: black;
                    a {
                        align-items: flex-start;
                        justify-content: flex-start;
                        margin-left: 3px;
                        text-decoration: none;
                        padding-left: 5px !important;
                        color: black;
                    }
                }
            }
        }
       
    }
}

.inline-img {
    margin-right: 3px;
    width: 18px;
}