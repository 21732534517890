.InputMessageContainer{
    // width: 95%;
    // height: 100%;
    // padding: 10px !important;
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    &-btn{
        cursor: pointer;
    }
    @media (max-width: "700px") {
        height: 120px !important;
        
    }
}

.react-chatbot-kit-chat-input-container-expand{
    box-shadow: rgba(16, 16, 18, 0.587) 0px 0px 10px 0px;
    height: 33% !important;
    display: flex;
    padding: 5px 0px;
    flex-direction: column;
    justify-content: space-between;
    
}
.react-chatbot-kit-chat-input-form{
    position: relative;
    &-bubble{
        top: -95%;
        left: 2%;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        &-text{
            position: absolute;
            margin-bottom: 5px;
            color: white;
            font-size: 14px;
            
        }
    }
    width: 97%;
    
    // @media (max-width: "450px") {
    //     font-size: 12px !important;
    //     padding: 8px !important;
        
    // }
}
.react-chatbot-menuIcon-rotated{
    transform: rotate(90deg);
}
.expandedMenu{
   
    margin-top: 10px;
    height: 100%;
    width: 100% !important;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    // text-align: center;
   
    &-item{
        img{
            margin-top: 15px;
            width: 35px;
            @media (max-width: "450px") {
                width: 25px;
            }
        }
        width: 24%;
        height: 44%;
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-around;
        text-align: center;
        padding: 0px 5px;
        font-size: 12px;
        @media (max-width: "450px") {
            font-size: 10px;
        }
        color: black;
        &-active{
            cursor: pointer;
        }
        background-color: #DBEAF5;
        border-radius: 5px;
        margin: 7px;
        &-soon{
            top: 4%;
            font-size: 9px;
            position: absolute;
            color: #FE2222;
        }
    }
}

.expandedOption{
    margin-top: 10px;
    height: 100%;
    width: 100% !important;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    &-container{
        width: 100%;
        padding: 10px;
        font-size: 14px;
        &-error{
           display: block;
           text-align: center;
           color: #FE2222;
           font-weight: 500;
        }
        &-item{
            color: #252D59;
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 5px 0px;
            cursor: pointer;
            span{
                &:last-child{
                    color: #F26729;
                    font-weight: 800;
                }
            }
           
            &:not(:last-child){
                border-bottom: 1px solid rgb(176, 176, 176);
            }
           
            &:hover{
                font-weight: 500;
            }
        }
    }
}
.react-chatbot-kit-chat-input{
    font-size: 15px !important;
    padding-right: 15px !important;
    @media (max-width: "700px") {
        font-size: 14px !important;
        
    }
    @media (max-width: "450px") {
        font-size: 12px !important;
        padding: 8px !important;
        
    }
    border-radius: 5px;
   &:focus{
       outline: 2px solid #3fa7d3 !important;
   }
}

.react-chatbot-kit-chat-input-container{
    min-height: 60px !important;
}

.react-chatbot-kit-chat-ipo { 
    position: absolute;
    right: 15%;
    width: 37px;
    cursor: pointer;
    margin-bottom: 2px;
    // font-size: 12px;
    // border: 1px solid #F26729;
    // border-radius: 100%;
    // background: #F26729;
    // color: whitesmoke;
    // padding: 6px;
    // font-weight: 600;
    // letter-spacing: 0.7px;

}


.notification-box {
    // width: 150px;
    // height: 75px;
    font-size: 14px;
    background-color: #252D59;
    color: #fff;
    padding: 8px;
    position: absolute;
    margin: 40px;
    float: left;
    bottom: 50%;
    right: 0%;
  }

  .notification-box.arrow-bottom:after {
    content: " ";
    position: absolute;
    right: 70px;
    bottom: -10px;
    border-top: 10px solid #252D59;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    border-bottom: none;
  }

  .container{
    &-popup{
        font-family: "Roboto" !important;
        position: fixed;
        padding: 15px 10px;
        background-color: #feffdf;
        border-radius: 10px;
        margin-left: -8px;
        border: 1px solid #aeb082;
        text-align: justify;
        
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;

        // border: 1px solid black;
        // display: flex;
        &-bottom{
            bottom: 10%;
        }
        

        &-top{
            top: 10%;
            
        }
        // right: 50%;
        // transform: translate(-50% -50%);
        // align-items: center;
        // justify-content: center;
        *{
            font-size: 13px;
        }
        .popup{
            display: contents !important;
        }
        width: 66%;
        // margin-left: -13%;
        max-height: 40vh;
        color: black;
        z-index: 99999;
        // overflow: scroll;
        &-close{
            color: white;
            position: absolute;
            top: -10%;
            font-size: 25px !important;
            right: -5%;
            background-color: rgba(16, 21, 1, 0.724);
            width: 40px;
            height: 40px;
            border-radius: 50%;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transform: rotate(45deg);
           
        }
        
    }
}